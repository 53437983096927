import { TabsMenu as TabsMenuUI } from "artisn-ui-react";
import { useRouter } from "next/router";
import React, { useState, useEffect } from "react";

import Styles from "./TabsMenu.styles";
import { TabsMenuProps as Props } from "./TabsMenu.types";
import CouponsTabsMenuItem from "../navigation/CouponsTabsMenuItem/CouponsTabsMenuItem";
// TODO: active if necessary.
// import DiscoverTabsMenuItem from "../navigation/DiscoverTabsMenuItem/DiscoverTabsMenuItem";
import LoyaltyTabsMenuItem from "../navigation/LoyaltyTabsMenuItem/LoyaltyTabsMenuItem";
import ShopTabsMenuItem from "../navigation/ShopTabsMenuItem/ShopTabsMenuItem";
import UserTabsMenuItem from "../navigation/UserTabsMenuItem/UserTabsMenuItem";
import CONSTANTS from "config/constants";
import { useTalkShop } from "contexts/talkShop/talkShop.context.hooks";

const { WITH_LOYALTY, WITH_STORE_COUPONS } = CONSTANTS.FEATURE_FLAGS;
const { WITH_TALK_SHOP } = CONSTANTS.FEATURE_FLAGS;
const { tablet } = CONSTANTS.BREAKPOINTS;

const TabsMenu: React.FC<Props> = props => {
  const { children } = props;
  const { pathname } = useRouter();
  const [active, setActive] = useState(-1);
  const { talkShopProvider, talkShopIdentifier } = useTalkShop();
  const talkShop = !!talkShopProvider && !!talkShopIdentifier && WITH_TALK_SHOP;

  useEffect(() => {
    if (pathname.startsWith("/categories")) {
      setActive(0);
    } else if (pathname.startsWith("/products")) {
      setActive(1);
    } else if (pathname.startsWith("/loyalty")) {
      setActive(2);
    } else if (pathname.startsWith("/coupons")) {
      setActive(3);
    } else if (pathname.startsWith("/profile")) {
      setActive(4);
    }
  }, [pathname]);

  const tabs = [
    <ShopTabsMenuItem key={0} active={active === 0} />,
    // TODO: active if necessary.
    // <DiscoverTabsMenuItem key={1} active={active === 1} />,
    WITH_LOYALTY ? <LoyaltyTabsMenuItem key={2} active={active === 2} /> : null,
    WITH_STORE_COUPONS ? (
      <CouponsTabsMenuItem key={3} active={active === 3} />
    ) : null,
    <UserTabsMenuItem key={4} active={active === 4} withMobileImage />
  ];

  if (talkShop) return <Styles className="TabsMenu">{children}</Styles>;

  return (
    <Styles className="TabsMenu">
      <TabsMenuUI tabs={tabs} maxMobileWidth={tablet + 1}>
        {children}
      </TabsMenuUI>
    </Styles>
  );
};

TabsMenu.defaultProps = {};

export default TabsMenu;
